angular.module('app.controllers').controller 'RegistrationCtrl', [
  '$scope', 'APIVisitor', '$timeout', '$location', 'APIConfirm', '$rootScope', '$http', 'smoothScr', 'APIData', '$cookies'
].append (self, APIVisitor, $timeout, $location, APIConfirm, root, $http, smoothScr, APIData, $cookies) ->

  self.formStep = 1
  self.referrer = null
  self.temp_ref = null

  params = $location.search()

  # ticket_category
  # 0 - Händler
  # 1 - Anwender
  # 2 - Lieferant

  (resetData = (refs = true) ->
    self.data = 
      gender: 'true'
      title: ''
      first_name: ''
      last_name: ''
      affix: ''
      email: ''
      password: ''

      company: ''
      address: ''
      zip: ''
      city: '' 
      country: '' 
      phone: '' 
      
      position: ''  
      locale: 'DE'
      source: params?.source || ''
      ticket_category: 1
      
      trader:
        id: null

      settings:
        additional_field: 
          additional_field_label_1: 'Psi-Nummer'
          additional_field_value_1: ''  

          additional_field_label_2: 'Gewerbenachweis'
          additional_field_value_2: ''  

          additional_field_label_3: 'Hiermit stimme ich den Datenschutzbestimmungen und AGB zu.'
          additional_field_value_3: false

          additional_field_label_4: 'Hiermit stimme ich zu, bis auf Widerruf Info-Mailings von md Xchange per E-Mail zu erhalten.'
          additional_field_value_4: false

          additional_field_label_5: 'Ich bin'
          additional_field_value_5: 'industriekunde'  

          additional_field_label_6: 'Händler'
          additional_field_value_6: true  


    self.fake = 
      file: null
      password: ''

    self.formProccess = false    
    self.formErrors   = null

    if self.rForm
      self.rForm.$setPristine() 
      self.rForm.$setUntouched()

    if refs && params.ref
      self.referrer = String(params.ref)
      $cookies.put("referrer", self.referrer)

      self.formProccess = true
      APIData.query {slug: 'referrer'}, (data) ->
        self.temp_ref = data.filter((item) -> return item.ref == self.referrer)[0]?.id || null
        self.referrer = null unless self.temp_ref?       

        self.formProccess = false
      , (errorData) ->
        return self.formProccess = false 
    else
      self.referrer = null
      #$cookies.remove("referrer")
      
  )()


  if params && angular.isDefined(params.password_restore)
    $location.path("/passwort-vergessen")

  if params && angular.isDefined(params.confirm)   
    self.formStep     = 2
    self.formProccess = true

    ticket = APIConfirm.get {hash: params.confirm}, () ->
      self.formProccess = false
      self.formSuccess  = true
    , (errorData) ->
      self.formProccess = false
      self.formSuccess  = false
      self.formErrors   = errorData.data.error

      if self.formErrors == 'E-Mail already verified'
        self.formErrors = 'Ihre E-Mail wurde bereits bestätigt. Wir freuen uns auf Ihren Besuch'
      
      if self.errorTimeout
        $timeout.cancel(self.errorTimeout)


  user_types = ["handler", "industriekunde", "supplier"]
  self.setTicket = (ticket_category) ->
    self.data.ticket_category = ticket_category
    self.data.settings.additional_field.additional_field_value_5 = user_types[self.data.ticket_category]

  self.saveForm = (form) ->
    self.rForm = form

  self.formSuccess  = false

  self.getUserTypeString = () ->
    userTypeMap =
      0: 'trader'
      1: 'user'
      2: 'supplier'
    return userTypeMap[self.data.ticket_category]

  self.register = () ->
    return unless self.rForm.$valid

    self.data.settings.additional_field.additional_field_value_6 = false if self.data.settings.additional_field.additional_field_value_5 == 'handler' || self.data.ticket_category == 0

    visitor = new APIVisitor self.data
    self.formProccess = true

    params = {}    
    if $cookies.get("referrer")
      if self.data.settings.additional_field.additional_field_value_5 == 'industriekunde' || self.data.ticket_category == 1
        params.ref = $cookies.get("referrer") || '' 
      else
        $cookies.remove("referrer")

    visitor.$save params, (data) ->
      gtagWrapper('event', 'conversion', {'send_to': 'AW-10861688951/n3LECLC6uaYDEPfwoLso'})
      gtagEvent({event: "sign_up", method: "email", typ: self.getUserTypeString()})

      self.formSuccess  = true
      $cookies.remove("referrer")

      if data.auth_ticket && self.fake.file 
        fd = new FormData()
        fd.append 'methods', 'notice'
        fd.append 'title', "Gewerbenachweis"
        fd.append 'shortcode', data.shortcode
        fd.append 'file', self.fake.file

        $http.post "api.php?path=/inquiries&_private=true&auth_ticket=" + data.auth_ticket, fd,
          transformRequest: angular.identity
          headers: 
            'Content-Type': undefined
        .then () ->
          #gtagWrapper('event', 'conversion', {'send_to': 'AW-10793931759/oOyFCKylvf0CEO-n-Zoo'})
          self.formProccess = false
          $timeout () -> smoothScr.scroll('#top', 0)
        , (errorData) ->
          self.formProccess = false
          self.formErrors   = errorData.data.error

          if self.registerTimeout
            $timeout.cancel(self.registerTimeout)

          self.registerTimeout = $timeout () ->
            self.formErrors = null
          , 5000

      else
        $timeout () -> smoothScr.scroll('#top', 0)

      resetData(false)

    , (errorData) ->
      self.formErrors   = errorData.data.error
      self.formProccess = false

      if self.formErrors == 'A ticket with this e-mail already signed up'
        self.formErrors = 'Diese E-Mail ist bereits angemeldet. <a href="/passwort-vergessen"><u>Passwort vergessen?</u></a>'
        self.data.email = ''

      if self.registerTimeout
        $timeout.cancel(self.registerTimeout)

      self.registerTimeout = $timeout () ->
        self.formErrors = null
      , 5000

  clearForm = () ->
    $timeout () ->
      input = document.getElementById('attachedFile')
      input.value = ''
      input.dispatchEvent(new Event('change'))
    , 100

  self.registerExh = () ->
    return unless self.exhForm.$valid      

    self.formProccess  = true
    self.formSuccess   = false
    self.formError     = null

    (new APIMail2 self.data).$save {}, (data) ->
      self.resetData()
      self.prForm.$setPristine()
      self.prForm.$setUntouched()

      self.formSuccess = true

    , (errorData) ->
      self.formError    = errorData.data.error
      self.formSuccess  = false
      self.formProccess = false

      self.updateCaptcha()
      
      if self.errorTimeout
        $timeout.cancel(self.errorTimeout)
        
      self.errorTimeout = $timeout () ->
        self.formError = null
      , 7000



angular.module('app.controllers').controller 'RegistrationModalCtrl', [
  '$scope', 'APIVisitor', '$timeout', '$location', '$rootScope', '$cookies', 'APIData', 'UI'
].append (self, APIVisitor, $timeout, $location, root, $cookies, APIData, UI) ->

  self.user_type  = 1
  self.referrer   = null
  self.temp_ref   = null

  self.user_data = 
    type1:
      title: "Anwender"
      list: ["Produktanfragen senden", "Favorisierte Lieferanten markieren", "Artikel merken", "Merkliste per Link an Kollegen schicken"]
    type0:
      title: "Händler"
      list: ["Produktanfragen bequem direkt über das Portal stellen", "Merkliste erstellen und diese per Link an Kollegen senden", "Individuell angepassten Content für Händler lesen", "Anfragen erhalten (wenn gelistet)"]      
    type2:
      title: "Lieferant"
      list: ["Für Sie angepassten Content lesen, und sich über die News der Branche informieren", "Darstellung Ihres Unternehmens und Ihrer Produkte aus Besuchersicht prüfen", "Inkl. Anfragenstellung aus Besuchersicht prüfen"]

  params = $location.search()

  self.saveForm = (form) ->
    self.rForm = form

  resetData = (refs = true) ->
    if self.registerTimeout
      $timeout.cancel(self.registerTimeout)

    self.data =    
      first_name: "n/a"
      last_name: "n/a"  
      email: ''
      password: ''
      trader:
        id: null

      ticket_category: 1

      settings:
        additional_field: 
          additional_field_label_1: 'Psi-Nummer'
          additional_field_value_1: ''  

          additional_field_label_2: 'Gewerbenachweis'
          additional_field_value_2: ''  

          additional_field_label_3: 'Hiermit stimme ich den Datenschutzbestimmungen und AGB zu.'
          additional_field_value_3: false

          additional_field_label_4: 'Hiermit stimme ich zu, bis auf Widerruf Info-Mailings von md Xchange per E-Mail zu erhalten.'
          additional_field_value_4: false

          additional_field_label_5: 'Ich bin'
          additional_field_value_5: 'industriekunde'  

          additional_field_label_6: 'Händler'
          additional_field_value_6: true  

    self.formSuccess  = false
    self.formProccess = false    
    self.formErrors   = null

    if self.rForm
      self.rForm.$setPristine() 
      self.rForm.$setUntouched()

    if refs && params.ref
      self.referrer = String(params.ref)
      $cookies.put("referrer", self.referrer)

      self.formProccess = true
      APIData.query {slug: 'referrer'}, (data) ->
        self.temp_ref = data.filter((item) -> return item.ref == self.referrer)[0]?.id || null
        self.referrer = null unless self.temp_ref?       

        self.formProccess = false
      , (errorData) -> return self.formProccess = false 
    else
      self.referrer = null      
  
  regModalEl    = document.getElementById('regModal')
  if regModalEl?
    root.regModal = bootstrap.Modal.getOrCreateInstance(regModalEl)
    #root.regModal.show()

    # init registration object on open reg modal
    regModalEl.addEventListener('shown.bs.modal', (event) ->
      resetData()
      self.$applyAsync()
    )

    # reset all data on close modal
    regModalEl.addEventListener('hidden.bs.modal', (event) ->
      resetData(false)
      self.$applyAsync()
    )

  user_types = ["handler", "industriekunde", "supplier"]
  self.setTicket = (ticket_category) ->
    self.user_type = ticket_category
    self.data.ticket_category = ticket_category
    self.data.settings.additional_field.additional_field_value_5 = user_types[self.data.ticket_category]

  self.register = () ->
    return unless self.rForm.$valid
    self.formProccess = true

    self.data.settings.additional_field.additional_field_value_6 = false if self.data.settings.additional_field.additional_field_value_5 == 'handler' || self.data.ticket_category == 0

    visitor = new APIVisitor self.data
    params = {}    
    if $cookies.get("referrer")
      if self.data.settings.additional_field.additional_field_value_5 == 'industriekunde' || self.data.ticket_category == 1
        params.ref = $cookies.get("referrer") || '' 
      else
        $cookies.remove("referrer")

    visitor.$save params, (data) ->
      
      gtagWrapper('event', 'conversion', {'send_to': 'AW-10861688951/n3LECLC6uaYDEPfwoLso'})
      self.formSuccess  = true
      $cookies.remove("referrer")

      if self.registerTimeout
        $timeout.cancel(self.registerTimeout)

      self.registerTimeout = $timeout () ->
        resetData(false)
      , 15000      

    , (errorData) ->

      self.formErrors   = errorData.data.error
      self.formProccess = false

      if self.formErrors in ['A ticket with this e-mail already signed up', 'Ein Ticket mit dieser E-Mail-Adresse wurde bereits registriert']
        self.formErrors = 'Diese E-Mail ist bereits angemeldet. <a href="/passwort-vergessen"><u>Passwort vergessen?</u></a>'
        self.data.email = ''

      UI.notify false, self.formErrors

      if self.registerTimeout
        $timeout.cancel(self.registerTimeout)

      self.registerTimeout = $timeout () ->
        self.formErrors = null
      , 5000


angular.module('app.controllers').controller 'ConfirmCtrl', [
  '$scope', '$timeout', '$location', 'APIConfirm', '$rootScope', 'UI','$cookies'
].append (self, $timeout, $location, APIConfirm, root, UI,$cookies) ->
  self.formProccess  = false
  self.formErrors    = null
  self.formSuccess   = false
  root.noMotivationModal = 1

  params = $location.search()
  
  if params && angular.isDefined(params.confirm)   
    self.formProccess = true

    APIConfirm.get {hash: params.confirm}, (data) ->
      gtagEvent({event: "sign_up_success"})
      self.formProccess = false
      self.formSuccess  = true


      registrationFromCookie = $cookies.get('new_registration_at')
      if(registrationFromCookie == 'pga_voting')
        $cookies.remove('new_registration_at')
        $location.path("/pgavoting")

      usercookie =
        shortcode:    data.shortcode
        auth_ticket:  data.auth_ticket
        role:         data.role

      $cookies.putObject('user', usercookie)
      root.$emit 'proveSession', false


    , (errorData) ->
      self.formProccess = false
      self.formSuccess  = false
      self.formErrors   = errorData.data.error

      if self.formErrors == 'E-Mail already verified'
        self.formErrors = 'Ihre E-Mail wurde bereits bestätigt. Wir freuen uns auf Ihren Besuch'
      
      UI.notify false, self.formErrors

      if self.confirmTimeout
        $timeout.cancel(self.confirmTimeout)

      self.confirmTimeout = $timeout () ->
        self.formErrors = null
      , 5000

  else
    $location.path("/")

angular.module('app.controllers').controller 'RegisterMotivateModalCtrl', [
  '$scope', '$timeout', '$location', 'APIConfirm', '$rootScope', 'UI','$cookies'
].append (self, $timeout, $location, APIConfirm, root, UI,$cookies) ->
  self.openRegistrationModal = () ->
    angular.element('#registerMotivateModal').modal('hide')
    angular.element('#regModal').modal('show')

angular.module('app.controllers').controller 'quickRegisterModal', [
  '$scope', '$timeout', '$location', 'APIConfirm', '$rootScope', 'UI','$cookies'
].append (self, $timeout, $location, APIConfirm, root, UI,$cookies) ->
  self.openRegistrationModal = () ->
    angular.element('#registerMotivateModal').modal('hide')
    angular.element('#regModal').modal('show')
